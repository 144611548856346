.team-bio {
	max-width: 500px;
	font-size: rem-calc(20);
	color: $white;
	margin: 0 auto 86px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	&:last-child {
		margin-bottom: 0;
	}
	h3 {
		color: $white;
		margin-bottom: 16px;
	}
	.image-wrap {
		text-align: center;
		margin: 0 10px 38px 0;
	}
	.image {
		background-color: $white;
		box-shadow: 10px 10px rgba($black, .2);
		margin-bottom: 20px;
		display: inline-block;
		img {
			margin-bottom: 0;
		}
	}
	.email-link {
		width: 100%;
		display: block;
	}
}

@include breakpoint(800px) {
	.team-bio {
		max-width: 1075px;
		flex-wrap: nowrap;
		.image {
			margin-bottom: 20px;
		}
		.image-wrap {
			flex-shrink: 0;
		}
		.text {
			padding-left: 32px;
		}
	}
}
/*----------------------------------------------------------*\
	Callouts

	Some of the callout styles come from the Foundation
	settings file. The styles listed here apply to the
	default callouts. Additional callout options can be
	seen on the styleguide.

\*----------------------------------------------------------*/

.callout {
	max-width: 635px;
	background: $brand;
	color: $white;
	padding: 20px;
	margin: 0 auto 60px;
	box-shadow: 10px 10px rgba($brand-accent, .7);
	.heading, h3 {
		color: $white;
	}
	p {
		font-size: rem-calc(22);
		line-height: 1.27;
		margin-bottom: 27px;
	}
	.button {
		font-size: rem-calc(18);
		padding: 16px 20px;
	}
}
.contact-callout {
	.heading {
		margin-bottom: 15px;
	}
	p {
		background-repeat: no-repeat;
		background-position: left top;
		font-size: rem-calc(20);
		padding-left: 30px;
		position: relative;
		margin-bottom: 22px;
		&.ico-map {
			background-image: url('/images/icons/map_point.svg');
		}
		&.ico-phone {
			background-image: url('/images/icons/phone_tan.svg');
		}
		&.ico-email {
			background-image: url('/images/icons/mail.svg');
			background-position: left 6px;
			margin-bottom: 0;
		}
	}
}

@include breakpoint(655px) {
	.callout {
		padding: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.text {
			padding-right: 50px;
		}
		.action {
			flex-shrink: 0;
		}
		p {
			margin: 0;
		}
	}
	.contact-callout {
		width: 100%;
		display: block;
		.contact-details {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 32px;
		}
		p {
			&.ico-phone {
				margin-bottom: 10px;
			}
		}
	}
}

@include breakpoint(large) {
	.contact-callout {
		.contact-details {
			flex-wrap: wrap;
		}
		.address {
			margin-bottom: 22px;
		}
	}
}

@include breakpoint(985px) {
	.contact-callout {
		.contact-details {
			flex-wrap: nowrap;
		}
		.address {
			margin-bottom: 0;
		}
		.phone-email {
			padding-left: 16px;
		}
	}
}
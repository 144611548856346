$shadow-width-small: 10px;
$shadow-width: 20px;

.media-object {
	max-width: 500px;
	margin: 0 auto 60px;
	.media {
		padding-right: $shadow-width-small;
		padding-bottom: $shadow-width-small;
		margin-bottom: 28px;
		img {
			box-shadow: $shadow-width-small $shadow-width-small rgba($brand-accent, .7);
			margin: 0 auto;
			display: block;
		}
	}
	h3 {
		margin-bottom: 16px;
	}
}

.media-object.flip {
	.media {
		padding-right: 0;
		padding-left: $shadow-width-small;
		img {
			box-shadow: -$shadow-width-small $shadow-width-small rgba($brand-accent, .7);
		}
	}
}

.media-object.last {
	margin-bottom: 0;
}

@include breakpoint(800px) {
	.media-object {
		max-width: 100%;
		margin-bottom: 80px;
		display: flex;
		align-items: center;
		.media {
			flex-basis: 45%;
			padding-right: $shadow-width;
			padding-bottom: $shadow-width;
			img {
				width: 100%;
				box-shadow: $shadow-width $shadow-width rgba($brand-accent, .7);
			}
		}
		.text {
			padding-left: 60px;
			flex-basis: 55%;
		}
	}

	.media-object.flip {
		.media {
			padding-left: $shadow-width;
			order: 1;
			img {
				box-shadow: -$shadow-width $shadow-width rgba($brand-accent, .7);
			}
		}
		.text {
			padding-left: 0;
			padding-right: 60px;
		}
	}
}

@include breakpoint(xlarge) {
	.media-object {
		.media {
			flex-basis: 524px;
		}
		.text {
			flex-grow: 1;
			padding-left: 120px;
		}
	}
	.media-object.flip {
		.text {
			padding-left: 0;
			padding-right: 120px;
		}
	}
}
/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form input[type="submit"] {
	background-color: $brand;
	font-family: $alt-font;
	font-weight: $alt-bold;
	color: $white;
	padding: 10px 20px;
	border: none;
	display: inline-block;
	line-height: 1.2;
	position: relative;
	overflow: hidden;
	&::before {
		content: '';
		background-color: darken($brand, 10%);
		width: 100%;
		height: 100%;
		transition: transform .3s;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		transform: translateX(calc(-100% + 10px));
	}
	span {
		position: relative;
		z-index: 50;
	}
	&:hover,
	&:focus {
		color: $white;
		cursor: pointer;
		text-decoration: none;
		&::before {
			transform: translateX(0%);
		}
	}
}
.button.small {
	font-size: rem-calc(14);
	padding: 8px 13px;
}
.button.large {
	font-size: rem-calc(24);
	padding: 18px 23px;
}
.button.short {
	padding-top: 8px;
	padding-bottom: 8px;
}
.button.tiny {
	font-size: rem-calc(12);
	padding: 6px 13px 6px 15px;
}
.button.alt {
	background: $brand;
	color: $white;
	&:hover,
	&:focus {
		background: $brand-accent;
	}
}
.button.tan {
	background-color: darken($brand-accent, 10%);
	&::before {
		background-color: $brand-accent;
	}
}
/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/


.page-header {
	background-color: $black-trans;
	width: 100%;
	position: fixed;
	padding: 10px 0;
	z-index: 300;
    .inner {
    	display: flex;
    	justify-content: space-between;
    	align-items: center;
    }
    .small-nav {
    	width: 94%;
    	position: absolute;
    	top: 64px;
    	z-index: 30;
    	display: none;
    }
    .main-nav-large {
    	display: none;
    }
    .nav-toggle {
    	font-family: $alt-font;
    	font-weight: $alt-bold;
    	padding-left: 15px;
    	text-transform: uppercase;
    	display: flex;
    	align-items: center;
    	position: relative;
    	z-index: 501;
    	a {
    		color: #666;
    		padding-right: 5px;
    		&:hover, &:focus {
    			color: $brand;
    		}
    	}
    	&:hover a, &:focus a {
    		color: $brand;
    	}
    	&.active a {
    		display: none;
    	}
    }
    .contact-icon {
    	padding-right: 15px;
    	img {
    		display: block;
    	}
    }
    .logo {
    	img {
    		width: auto;
    		max-height: 50px;
    		margin: 0;
    	}
    	.logo-mark {
			max-height: 35px;
    		opacity: 0;
    		visibility: hidden;
    		position: absolute;
    	}
    	.full-logo {
    		opacity: 1;
    		visibility: visible;
    		position: relative;
    	}
    }
    img {
    	margin-bottom: 0;
    }
    .alert-bar {
    	margin: -10px 0 10px;
    }
}

body.sticky .page-header {
	padding: 5px 0;
	.logo {
		.logo-mark {
			opacity: 1;
			visibility: visible;
			position: relative;
    		transition: opacity .3s, visibility .3s;
		}
		.full-logo {
			opacity: 0;
			visibility: hidden;
			position: absolute;
		}
	}
	.alert-bar {
		margin-top: -5px;
	}
}

@include breakpoint(768px) {
	.page-header {
		.main-nav,
		.main-nav.open,
		.nav-toggle,
		.contact-icon {
			display: none;
		}
	    .main-nav-large {
	    	display: flex;
	    }
	    .logo {
	    	img {
	    		width: auto;
	    		max-height: 45px;
	    	}
	    	.logo-mark {
	    		max-height: 45px;
	    	}
	    }
	}
}

@include breakpoint(1220px) {
	.page-header {
		.inner {
			align-items: center;
		}
		.logo {
			img {
				max-height: 100%;
			}
		}
	}
}

@include breakpoint(1200px) {
	.page-header .inner {
	}
}

/*------------------------------------*\
    @group Navigation
\*------------------------------------*/
body.nav-overlay {
	overflow: hidden;
}

.main-nav {
	background-color: rgba(0,0,0,.9);
	width: 100%;
	height: 100vh;
	text-align: center;
	padding: 0 22px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 500;
	&.closed {
	  display: none;
	}
	.overlay-logo {
		margin: 112px 0 56px;
	}
	ul {
		font-family: $alt-font;
		font-weight: $alt-bold;
		font-size: rem-calc(16);
		a {
			color: $white;
			&:hover, &:focus, &.active {
				color: $brand-accent;
			}
		}
		li {
			margin-bottom: 22px;
		}
	}
	.nav-separator {
		width: 100%;
		height: 4px;
		background: repeating-linear-gradient(90deg, transparent, transparent 7px, $brand-accent 7px, $brand-accent 14px);
		display: block;
		margin: 56px 0;
	}
	.contact-info {
		font-size: rem-calc(17);
		color: $tan;
		p {
			margin-bottom: 0;
		}
		a {
			font-family: $alt-font;
			font-weight: $alt-bold;
			font-size: rem-calc(16);
			letter-spacing: -.06px;
			color: $brand-accent;
		}
	}
}

.main-nav-large {
	font-family: $alt-font;
	font-weight: $alt-bold;
	font-size: rem-calc(12);
	letter-spacing: -.06px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	a {
		color: $white;
		&:hover, &:focus, &.active {
			color: $brand-accent;
		}
	}
	li {
		margin-left: 40px;
		display: inline-block;
		&:first-child {
			margin-left: 0;
		}
	}
	ul {
		text-align: right;
		order: 1;
		flex-basis: 100%;
	}
	.contact-info {
		background: url('/images/icons/phone_tan.svg') no-repeat left top;
		background-size: 14px 14px;
		color: $brand-accent;
		padding-left: 22px;
		margin: 0 0 10px;
	}
}

@include breakpoint(1100px) {
	.main-nav-large {
		margin-top: -8px;
		flex-wrap: nowrap;
		margin-top: 0;
		ul {
			order: 0;
			margin-right: 30px;
			padding-right: 30px;
			border-right: solid 1px $black;
		}
		.contact-info {
			flex-shrink: 0;
			order: 1;
			margin: 0;
		}
	}
}

@include breakpoint(1220px) {
	.main-nav-large {
		font-size: rem-calc(16);
		.contact-info {
			background-size: 18px 18px;
			background-position: left 3px;
			padding-left: 27px;
		}
	}
}
/* @end Navigation */
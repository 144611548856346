/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    background-color: $brand-accent;
    font-size: rem-calc(17);
    padding: 28px 0;
    text-align: center;
    p {
        margin: 0;
    }
    .logo {
        img {
            max-height: 50px;
            margin-bottom: 16px;
        }
    }
}

@include breakpoint(600px) {
    .page-footer {
        padding: 16px 0;
        .inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .logo {
            img {
                max-height: 40px;
                margin-bottom: 0;
            }
        }
    }
}
/*----------------------------------------------------------*\
   Blockquote

   Blockquote styling with large quote image. Background
   color can be overriden with the background (and gradient)
   classes listed on the styleguide.

\*----------------------------------------------------------*/
blockquote {
	margin: 20px 0;
	border: none;
	padding: 0;
	p {
		font-family: $alt-font;
		font-size: rem-calc(16);
		font-style: italic;
		line-height: 1.5;
		margin-bottom: 20px;
		&::before {
			content: '';
			background-image: url('/images/cssimg/left_quote_mark.svg');
			width: 32px;
			height: 23px;
			display: inline-block;
			margin-right: 5px;
		}
	}
	cite {
		font-size: rem-calc(17);
		line-height: 1.18;
		font-style: normal;
		line-height: 1.1;
		padding-left: 20px;
		&::before {
			display: none;
		}
		.name {
			font-size: rem-calc(22);
			font-weight: $main-bold;
			color: $brand;
			display: block;
		}
	}
}
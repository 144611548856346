.alert-bar {
	background-color: $brand;
	font-family: $heading-font;
	font-size: rem-calc(16);
	color: $white;
	text-align: center;
	padding: 10px 0;
	p {
		margin: 0 0 10px;
	}
	.inner {
		display: block;
	}
}

@include breakpoint(600px) {
	.alert-bar {
		text-align: left;
		.inner {
			display: grid;
			grid-template-columns: auto auto;
			grid-column-gap: 20px;
			align-items: center;
			justify-content: center;
		}
		p {
			margin: 0;
		}
	}
}
/*----------------------------------------------------------*\
    Slider

    All styles for sliders uses throughout the site are in
    this file.

\*----------------------------------------------------------*/
.quote-slider {
	max-width: 635px;
	text-align: center;
	margin: 0 auto 60px;
	position: relative;
	.header-image {
		margin: 0 auto 33px;
	}
	.slick-arrow {
		font-size: 0;
		position: absolute;
		bottom: 0;
		left: 44%;
		&:hover {
			cursor: pointer;
		}
	}
	.slick-next {
		left: auto;
		right: 44%;
	}
	.slick-prev::before {
		@include css-triangle(10px, $brand-accent, left);
	}
	.slick-next::before {
		@include css-triangle(10px, $brand-accent, right);
	}
	.quote-slide {
		padding-bottom: 20px;
	}
	blockquote cite {
		padding-left: 0;
	}
}

@include breakpoint(medium) {
	.quote-slider {
		.slick-arrow {
			left: 45%;
		}
		.slick-next {
			left: auto;
			right: 45%;
		}
	}
}

@include breakpoint(800px) {
	.quote-slider {
		.slick-arrow {
			left: 46%;
		}
		.slick-next {
			left: auto;
			right: 46%;
		}
	}
}

/* @end */
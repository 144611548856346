/*------------------------------------*\
    @group Home Template
\*------------------------------------*/
.page-intro {
	display: grid;
	justify-content: center;
	grid-gap: 30px;
	margin-bottom: 60px;
	.media {
		text-align: center;
		img {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(800px) {
	.page-intro {
		grid-template-columns: 2fr 1.5fr;
		margin-bottom: 72px;
	}
}

@include breakpoint(960px) {
	.page-intro .media img {
		width: 100%;
	}
}

@include breakpoint(1000px) {
	.page-intro {
		grid-template-columns: 2fr 1fr;
	}
}

.advocate-section {
	background-image: url('https://sullivan-reberger.imgix.net/sections/bg_idaho_landscape.jpg?auto=format');
	background-position: center top;
	background-repeat: no-repeat;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		background-image: url('https://sullivan-reberger.imgix.net/sections/bg_idaho_landscape@2x.jpg?auto=format');
		background-size: 501px 445px;
	}
}

@include breakpoint(medium) {
	.advocate-section {
		background-image: url('https://sullivan-reberger.imgix.net/sections/bg_idaho_landscape_lrg.jpg?auto=format');
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('https://sullivan-reberger.imgix.net/sections/bg_idaho_landscape_lrg@2x.jpg?auto=format');
			background-size: 2250px 1460px;
		}
	}
}

.about-section {
	background-image: url('/images/cssimg/bg_topography.svg');
	background-color: $brand;
	background-position: center;

}

.contact-section {
	background-image: url('/images/cssimg/bg_compass.svg');
	background-position: 20px -50px;
	background-size: 550px auto;
	background-repeat: no-repeat;
	padding-top: $full-section-spacing-small;
	padding-bottom: $full-section-spacing-small;
	.form {
		margin-bottom: 56px;
	}
	.callout {
		margin-bottom: 0;
	}
}

@include breakpoint(large) {
	.contact-section {
		background-image: url('/images/cssimg/bg_compass.svg');
		background-size: 958px auto;
		background-position: 50vw calc(100% + 200px);
	}
}

@include breakpoint(large) {
	.contact-section {
		padding-top: $full-section-spacing;
		padding-bottom: $full-section-spacing;
		.inner {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: flex-start;
			grid-gap: 30px;
		}
		.form {
			margin-bottom: 0;
		}
	}
}

/* @end Home Template */


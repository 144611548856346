/*------------------------------------*\
    @group Hero
\*------------------------------------*/
.hero {
	background-image: url('https://sullivan-reberger.imgix.net/hero/bg_capitol.jpg?auto=format');
	background-position: center center;
	background-size: cover;
	height: 700px;
	color: $white;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		background-image: url('https://sullivan-reberger.imgix.net/hero/bg_capitol@2x.jpg?auto=format');
	}
	.inner {
		height: 700px;
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
	}
	.hero-content {
		max-width: 700px;
		width: 100%;
		background-color: rgba($brand, .9);
		padding: 30px 20px;
		margin-bottom: 30px;
		p {
			margin-bottom: 0;
		}
	}
	h1 {
		color: $white;
	}
}

@include breakpoint(medium) {
	.hero {
		background-image: url('https://sullivan-reberger.imgix.net/hero/bg_capitol_lrg.jpg?auto=format');
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('https://sullivan-reberger.imgix.net/hero/bg_capitol_lrg@2x.jpg?auto=format');
		}
	}
}

@include breakpoint(large) {
	.hero {
		height: 900px;
		.inner {
			height: 900px;
		}
		.hero-content {
			margin-bottom: 70px;
		}
	}
}

//Loops over $hero-bg in _variables.scss and creates files with mixin
@each $name, $filename in $hero-bg {
  .#{$name} {
	@include hero-image($filename: $filename);
	background-color: map-get($hero-bg-color, $name);
  }
}

/* @end Hero */
/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

//Font families
$sans-serif-font: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
$serif-font: "EB Garamond", Baskerville, "Times New Roman", serif;
$icon-font: 'StarterTemplate';
$heading-font: $sans-serif-font;
$main-font: $serif-font;
$alt-font: $heading-font;

//Font weights
$main-normal: 400;
$main-bold: 700;
$alt-normal: 400;
$alt-bold: 700;
$heading-normal: $alt-normal;
$heading-bold: $alt-bold;

//Font sizes
$font-sizes: (
  small: (rem-calc(17), 1.18),
  default: (rem-calc(22), 1.27),
  large: (rem-calc(25), 1.07),
  small-desktop: (rem-calc(17), 1.47),
  default-desktop: (rem-calc(22), 1.5),
  large-desktop: (rem-calc(30), 1.5)
);


//Styleguide colors
$brand:				 #3a6154;
$brand-accent:		 #d4bf8c;
$light-green:		 #78c570;
$tan:	  			 #fcf9f1;
$blue:				 #3a5b75;
$dark-gray:          #3a3d3c;
$white:              #FFFFFF;
$black:              #000000;
$gray:               #DDDDDD;

$colors: (
	'brand':			 $brand,
	'brand-accent':		 $brand-accent,
	'light-green':		 $light-green,
	'tan':	 			 $tan,
	'blue':	 			 $blue,
	'dark-gray':	 	 $dark-gray,
	'white':             $white,
	'black':             $black
);

//Gradients, for use on backgrounds only
$gradients: (
	'white': linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $dark-gray;

//Link colors
$link-base: $light-green;
$link-active: $brand-accent;

//Social media brand colors
$social: (
	'twitter': 		 #00aced,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #517fa4,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow: 1px 0 1px rgba(0,0,0,0.65);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow: 0 1px 1px rgba(0,0,0,1);

//Rounded corners
$border-round: 1000px;
$border-radius: 4px;

//Section spacing
$full-section-spacing-small: 		56px;
$content-section-spacing-small: 	90px;
$content-sub-section-spacing-small: 60px;
$intro-section-spacing-small: 		30px;
$full-section-spacing: 				100px;
$content-section-spacing: 			150px;
$content-sub-section-spacing: 		90px;
$intro-section-spacing: 			60px;

//Hero backgrounds
$hero-bg: (
	'home-orange': test
);

$hero-bg-color: (
	'home-orange': #ff9900
);
/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    padding: 56px 0;
}

@include breakpoint(large) {
	.page-content {
	    padding: 100px 0;
	}
}

.section-intro {
	max-width: 800px;
	@include font-size(large);
	text-align: center;
	margin: 0 auto;
	&.light-text {
		color: $white;
		h1,h2,h3,h4 {
			color: $white;
		}
	}
}

@include breakpoint(large) {
	.section-intro {
		@include font-size(large-desktop);
	}
}
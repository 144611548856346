.logo-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	justify-content: center;
	grid-gap: 32px;
	margin: 0 auto;
	img {
		margin: 0 auto;
	}
}

@include breakpoint(medium) {
	.logo-grid {
		grid-template-columns: repeat(3, 1fr);
	}
}

@include breakpoint(large) {
	.logo-grid {
		grid-template-columns: repeat(4, 1fr);
	}
}

@include breakpoint(xlarge) {
	.logo-grid {
		grid-template-columns: repeat(6, 1fr);
	}
}